<template>
  <div class="scale-devices flex flex-column gap-4">
    <div class="flex items-center justify-end gap-2 w-100">
      <div class="flex gap-2">
        <b-button
          size="sm"
          variant="primary"
          class="text-nowrap"
          @click="createDevice()"
        >
          Add new scale
        </b-button>
      </div>
    </div>

    <div class="font-bold">
      Showing {{ devices.length | format }} scale(s)
    </div>

    <table class="table table-google table-devices">
      <thead>
        <tr>
          <th>ID</th>
          <th>Description</th>
          <th>Model</th>
          <th>Token</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="4">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="devices.length === 0">No devices found</span>
            </div>
          </td>
        </tr>

        <tr v-for="device in devices" :key="device.id">
          <td class="row-fit text-right">{{ device.id }}</td>
          <td>{{ device.device_description }}</td>
          <td>{{ device.device_model }}</td>
          <td class="row-fit">{{ device.device_token }}</td>
          <td class="row-fit">
            <div class="flex gap-2">
             <b-button
                variant="primary"
                size="sm"
                @click="editDevice(device)"
              >
                Edit
              </b-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <device-editor
      v-if="editModal.showModal"
      :data="editModal"
      @updated="fetchDevices()"
    />
  </div>
</template>

<script>
const DeviceEditor = () => import('@/components/scale/DeviceEditor.vue');

export default {
  name: 'ScaleDevices',
  components: {
    DeviceEditor,
  },
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      devices: [],
      editModal: {
        showModal: false,
        deviceId: null,
      },
    };
  },
  methods: {
    fetchDevices() {
      this.loadingCount++;
      this.$http
        .get('/scale/devices')
        .then((res) => {
          this.devices = res.body.devices;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch devices: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    createDevice() {
      this.loadingCount++;
      this.$http
        .post('/scale/devices')
        .then((res) => {
          const { device } = res.body;
          this.devices.push(device);
          this.editDevice(device);
        })
        .catch((err) => {
          this.$toast.error(`Failed to create device: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
    editDevice(device) {
      this.editModal.deviceId = device.id;
      this.editModal.showModal = true;
    },
  },
  created() {
    this.fetchDevices();
  },
};
</script>

<style lang="scss" scoped>
  .scale-devices {
    width: 800px;
  }
</style>
